import Environment from '../Environments';
import { IDailySpecial } from '../Types/base';
import { IProduct, IBaseDiscount } from '@magistrmartin/eshop-frontend-shared';
import { nullOrUndefined, round } from './Common';
import { DiscountsStatus } from './Discounts';
import { reducers } from './Reducers';

export const getShortDescription = (product: IProduct) => {
  const maxLen = 256;

  const splited = extractText(product.description || '')
    .split('.')
    .map(decodeEntities);
  let res = splited[0];
  for (let i = 1; res.length < maxLen && i < splited.length; i++) {
    res += '.' + splited[i];
  }
  return res + '.';
};

const decodeEntities = (raw: string) => {
  const e = document.createElement('textarea');
  e.innerHTML = raw;
  return e.textContent || raw;
};

export const getAllDiscounts = (prod: IProduct) => {
  const res: IBaseDiscount[] = [];
  if (prod.freeDelivery) res.push(prod.freeDelivery);
  if (prod.present) res.push(prod.present);
  if (prod.mixDiscount) res.push(prod.mixDiscount);
  if (prod.secondItemDiscount) res.push(prod.secondItemDiscount);
  if (prod.specialDiscount) res.push(prod.specialDiscount);
  if (prod.crossDiscount) res.push(prod.crossDiscount);
  return res;
};

export const isDiscountValid = (dis: IBaseDiscount) =>
  (nullOrUndefined(dis.validity?.from) || new Date(dis.validity?.from || '').getTime() <= new Date().getTime()) &&
  (nullOrUndefined(dis.validity?.to) || new Date(dis.validity?.to || '').getTime() >= new Date().getTime());

export const containsDiscount = (prod: IProduct, discount: IBaseDiscount) =>
  getAllDiscounts(prod)
    .map((d) => d.id)
    .includes(discount.id);

export const getPrice = (
  product: {
    reccomendedPrice?: number;
    reccomendedPricere?: number;
    totalPrice?: number;
    actionPrice?: number;
    heurekaPrice?: number;
    sellLimit?: number;
    id: number;
    customHeurekaPrice?: number;
    isRegulated?: boolean;
  },
  dailySpecials: IDailySpecial[]
) =>
  getTodaySpecial(dailySpecials)?.productId === product.id && getTodaySpecial(dailySpecials)?.specialPrice
    ? getTodaySpecial(dailySpecials)?.specialPrice || 0
    : Environment.useHeurekaPrices
    ? getHeurekaPrice(product)
    : (product.actionPrice || 0) === 0
    ? product.reccomendedPrice || product.reccomendedPricere || product.totalPrice || 0
    : product.actionPrice || 0;

const getHeurekaPrice = (product: {
  reccomendedPrice?: number;
  reccomendedPricere?: number;
  totalPrice?: number;
  actionPrice?: number;
  heurekaPrice?: number;
  sellLimit?: number;
  customHeurekaPrice?: number;
  id: number;
  isRegulated?: boolean;
}) => {
  if (product.isRegulated) {
    return Math.min(
      round(infiniteIfZero(product.actionPrice), 0),
      round(infiniteIfZero(product.heurekaPrice), 0),
      round(infiniteIfZero(product.reccomendedPrice), 0),
      round(infiniteIfZero(product.customHeurekaPrice), 0)
    );
  } else {
    if ((product.customHeurekaPrice || 0) > 0) {
      return product.customHeurekaPrice || 0;
    } else {
      if ((product.heurekaPrice || 0) > 0 && (product.heurekaPrice || 0) >= (product.sellLimit || 0)) {
        return product.heurekaPrice === product.sellLimit ? product.heurekaPrice || 0 : (product.heurekaPrice || 0) - 1;
      } else {
        return (product.actionPrice || 0) === 0
          ? product.reccomendedPrice || product.reccomendedPricere || product.totalPrice || 0
          : product.actionPrice || 0;
      }
    }
  }
};

const infiniteIfZero = (n?: number) => ((n || 0) === 0 ? 9999999999 : n || 0);

const getTodaySpecial = (specials: IDailySpecial[]) =>
  (specials || []).filter((s) => new Date(s.validAt).toDateString() === new Date().toDateString())[0] as
    | IDailySpecial
    | undefined;

export const getUnitPrice = (product: IProduct, dailySpecials: IDailySpecial[]) =>
  getPrice(product, dailySpecials) / (product.ammount || 1);

const extractText = (htmlString: string) => htmlString.replace(/<[^>]+>/g, '');

export const getTotalProductPrice = (
  ammount: number,
  p: IProduct,
  discountsStatus: DiscountsStatus,
  dailySpecials: IDailySpecial[]
) =>
  (ammount - discountsStatus.getNumberOfFreeUnits(p)) * getPrice(p, dailySpecials) -
  discountsStatus.getAdditionalDiscountForProduct(p) +
  discountsStatus.getNumberOfFreeUnits(p);

// In the future, it will be deciding also on manufacturer nationality.
export const showCzechIcon = (product: IProduct) => {
  return product.isCzech === true;
};

const productIsVeterinary = (product: IProduct): boolean =>
  product.characteristics?.toLocaleLowerCase().includes('veterinární léčivý přípravek') ?? false;

const productsWithAtLeastOneVeterinary = (products: IProduct[]): boolean =>
  products.length > 0 && products.map((p) => productIsVeterinary(p)).reduce(reducers.or, false);

const productContainsSUKL = (product: IProduct): boolean =>
  product.sukl !== null && product.sukl !== undefined && parseInt(product.sukl) !== 0 && product.sukl !== '';

const productsWithAtLeastOneSUKL = (products: IProduct[]): boolean =>
  products.length > 0 && products.map((p) => productContainsSUKL(p)).reduce(reducers.or, false);

const productsWithAtLeastOneNoVeterinarySUKL = (products: IProduct[]): boolean =>
  products.length > 0 &&
  products.map((p) => productContainsSUKL(p) && !productIsVeterinary(p)).reduce(reducers.or, false);

export const setCorrectVerifyLogos = (
  setSuklVerifyLogoVisible: (visible: boolean) => void,
  setVeterinaryLogoVisible: (visible: boolean) => void,
  products: IProduct[]
) => {
  if (!productsWithAtLeastOneSUKL(products)) {
    setVeterinaryLogoVisible(false);
    setSuklVerifyLogoVisible(false);
    return;
  }
  setVeterinaryLogoVisible(productsWithAtLeastOneVeterinary(products));
  setSuklVerifyLogoVisible(productsWithAtLeastOneNoVeterinarySUKL(products));
};
