const Environment =
  process.env.REACT_APP_ESHOP_VARIANT === 'marek'
    ? {
        domain: 'magistrmarek.cz',
        email: 'lekarna@magistrmarek.cz',
        phoneAndHours: '601 501 750 Po-Pá 7:30 - 17:00',
        contactsPage: '/staticSite/64',
        logoPath: '/img/logoMarek.webp',
        orderFinishImagePath: '/img/FinishedOrderImageMarek.webp',
        facebookLink:
          'https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FMagistrMarek&tabs&width=340&height=130&small_header=false&adapt_container_width=false&hide_cover=false&show_facepile=false&appId',
        disablePharmaReservation: true,
        hideSUKLLogo: false,
        useHeurekaPrices: true,
        hideHeaderIcons: true,
        registerText: 'Registrujte se k Magistru Markovi',
        showReservation: false,
        fullName: 'Magistr Marek',
        fullName2ndForm: 'Magistra Marka',
        fullName3rdForm: 'Magistrem Markem',
        heurekaConversionKey: '123B29A508C5445D1E2618DFADB3CEB9',
        useFoxentry: true,
      }
    : process.env.REACT_APP_ESHOP_VARIANT === 'adam'
      ? {
          domain: 'magistradam.cz',
          email: 'lekarna@magistradam.cz',
          phoneAndHours: '601 501 750 Po-Pá 7:30 - 17:00',
          contactsPage: '/staticSite/66',
          logoPath: '/img/logoAdam.webp',
          orderFinishImagePath: '/img/FinishedOrderImageMartin.webp',
          facebookLink:
            'https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FMagistrAdam&tabs&width=340&height=130&small_header=false&adapt_container_width=false&hide_cover=false&show_facepile=false&appId',
          disablePharmaReservation: true,
          hideSUKLLogo: true,
          useHeurekaPrices: false,
          hideHeaderIcons: true,
          registerText: 'Registrujte se k Magistru Adamovi',
          showReservation: false,
          fullName: 'Magistr Adam',
          fullName2ndForm: 'Magistra Adama',
          fullName3rdForm: 'Magistrem Adamem',
          heurekaConversionKey: '',
          useFoxentry: false,
        }
      : process.env.REACT_APP_ESHOP_VARIANT === 'patrik'
        ? {
            domain: 'magistrpatrik.cz',
            email: 'lekarna@magistrpatrik.cz',
            phoneAndHours: '601 501 750 Po-Pá 7:30 - 17:00',
            contactsPage: '/staticSite/65',
            logoPath: '/img/logoPatrik.webp',
            orderFinishImagePath: '/img/FinishedOrderImagePatrik.webp',
            facebookLink:
              'https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FMagistrPatrik&tabs&width=340&height=130&small_header=false&adapt_container_width=false&hide_cover=false&show_facepile=false&appId',
            disablePharmaReservation: true,
            hideSUKLLogo: false,
            useHeurekaPrices: true,
            hideHeaderIcons: true,
            registerText: 'Registrujte se k Magistru Patrikovi',
            showReservation: false,
            fullName: 'Magistr Patrik',
            fullName2ndForm: 'Magistra Patrika',
            fullName3rdForm: 'Magistrem Patrikem',
            heurekaConversionKey: '3B5506CCD21B0BD54596D502CF3407BF',
            useFoxentry: false,
          }
        : {
            domain: 'magistrmartin.cz',
            email: 'lekarna@magistrmartin.cz',
            phoneAndHours: '601 501 750 Po-Pá 7:30 - 17:00',
            contactsPage: '/staticSite/21',
            logoPath: '/img/logo.webp',
            orderFinishImagePath: '/img/FinishedOrderImageMartin.webp',
            facebookLink:
              'https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FMagistrMartin&tabs&width=340&height=130&small_header=false&adapt_container_width=false&hide_cover=false&show_facepile=false&appId',
            disablePharmaReservation: false,
            hideSUKLLogo: false,
            useHeurekaPrices: false,
            hideHeaderIcons: false,
            registerText: 'Registrujte se k Magistru Martinovi',
            showReservation: true,
            fullName: 'Magistr Martin',
            fullName2ndForm: 'Magistra Martina',
            fullName3rdForm: 'Magistrem Martinem',
            heurekaConversionKey: '',
            useFoxentry: false,
          };

export default Environment;
