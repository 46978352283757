import React, { useEffect, useState } from 'react';
import { FilterType } from './Offer';
import CategoryOffer from './CategoryOffer';
import CriteriaOffer from './CriteriaOffer';
import EText from '../../Components/EText';
import PaginatedProducts, { ProductPagesToShow } from './PaginatedProducts';
import { produce } from 'immer';
import { setCorrectVerifyLogos } from '../../Utils/Product';
import { useLayout } from '../../Layout/LayoutContext';
import { IFilterState } from '../../Components/Filters';
import { useProductsPaginated } from '../../Hooks/Products';
import { qualityLabels } from '../../Utils/QualityLabels';
import { SortBy } from '../../Components/SortSelect';
import { Container } from '@material-ui/core';

interface IProps {
  id: string;
  filterType: FilterType;
}

export const offerFields = `${qualityLabels
  .map((l) => l.name)
  .join(
    ','
  )},age,mass,formId,id,images,title,subtitle,shortDescription,reccomendedPrice,reccomendedPricere,heurekaPrice,ammount,unit,characteristics,totalPrice,actionPrice,relevanceScore,maxCartAmount,stockAmmount,expiration,pharmasStock,sellLimit,sukl,unavailable,customHeurekaPrice,isRegulated,reccomendedAlternative,customExpiration,productMass,isCzech,freeDelivery,present,mixDiscount,secondItemDiscount,specialDiscount,crossDiscount`;

export interface OfferParamsState {
  filter: IFilterState | undefined;
  sortBy: SortBy;
}
const ITEMS_PER_PAGE = 16;

const EMPTY_PRODUCT_PAGES_TO_SHOW: ProductPagesToShow = { containedPages: [], products: [] };

const PaginatedOffer = ({ id, filterType }: IProps) => {
  console.log('pag render!');
  const layout = useLayout();
  const [currentPage, setCurrentPage] = useState(0);
  const [shouldScrollToTop, setShouldScrollToTop] = useState(false); // Because of React Query, calling scrolling up does not work on click on paginator - we need a variable...
  const [bannerToShow, setBannerToShow] = useState<string | undefined>();
  const [offerParamsState, setOfferParamsState] = useState<OfferParamsState>({
    filter: undefined,
    sortBy: SortBy.RELEVANCE,
  });

  const {
    isLoading: areProductsLoading,
    isFetching,
    isError,
    data: latestProductsPage,
  } = useProductsPaginated(filterType, id, currentPage, ITEMS_PER_PAGE, offerFields, {
    ...offerParamsState,
    filter: offerParamsState.filter?.set ? offerParamsState.filter : undefined,
  }); // TODO: Pass sort by

  // Showing products is a bit complicated, because of the "Load more" button
  const [productPagesToShow, setProductPagesToShow] = useState<ProductPagesToShow>(EMPTY_PRODUCT_PAGES_TO_SHOW);

  const resetInfiniteScrolling = () => {
    setProductPagesToShow(EMPTY_PRODUCT_PAGES_TO_SHOW);
    setCurrentPage(0);
  };

  useEffect(() => {
    resetInfiniteScrolling();
  }, [offerParamsState]);

  useEffect(() => {
    if (
      latestProductsPage &&
      !productPagesToShow.containedPages.some((pageNumber) => pageNumber === latestProductsPage.number)
    ) {
      const newProductsToShow = produce(productPagesToShow, (draft) => {
        draft.containedPages.push(latestProductsPage.number);
        draft.products = draft.products.concat(latestProductsPage.content);
      });
      setProductPagesToShow(newProductsToShow);
    }
  }, [id, filterType, latestProductsPage, productPagesToShow]);

  useEffect(() => {
    console.log(productPagesToShow.products);
    setCorrectVerifyLogos(
      layout.setSuklVerifyLogoVisible,
      layout.setVeterinaryLogoVisible,
      productPagesToShow.products
    );
  }, [productPagesToShow, layout]);

  useEffect(() => {
    if (shouldScrollToTop && productPagesToShow.products.length > 0 && productPagesToShow.containedPages.length === 1) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setShouldScrollToTop(false);
    }
  }, [productPagesToShow, shouldScrollToTop]);

  return (
    <>
      <Container maxWidth="lg">
        {filterType === FilterType.CATEGORY ? (
          <CategoryOffer
            categoryId={id}
            setBannerToShow={setBannerToShow}
            productsCount={latestProductsPage?.totalElements}
            offerParamsState={offerParamsState}
            setOfferParamsState={setOfferParamsState}
          />
        ) : filterType === FilterType.BRAND ||
          filterType === FilterType.PRODUCT_LINE ||
          filterType === FilterType.SUPPLIER ? ( // TODO: later this can be simplified with easy else (when will be all filter types merged together...)
          <CriteriaOffer
            filterType={filterType}
            id={id}
            offerParamsState={offerParamsState}
            setOfferParamsState={setOfferParamsState}
          />
        ) : (
          <EText>Tato stránka neexistuje.</EText>
        )}

        {isError ? (
          <EText>Při načítání produktů došlo k chybě.</EText>
        ) : (
          <>
            <PaginatedProducts
              productPagesToShow={productPagesToShow}
              bannerToShow={bannerToShow}
              areProductsFetching={isFetching}
              areProductsLoading={areProductsLoading}
              totalPages={latestProductsPage?.totalPages}
              isLastPage={latestProductsPage?.last}
              currentPage={currentPage}
              onPaginationChange={(p) => {
                setProductPagesToShow(EMPTY_PRODUCT_PAGES_TO_SHOW);
                setCurrentPage(p - 1);
                setShouldScrollToTop(true);
              }}
              onLoadMoreButtonClick={() => setCurrentPage(currentPage + 1)}
            />
          </>
        )}
      </Container>
    </>
  );
};

export default PaginatedOffer;
